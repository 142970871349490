import React from "react"
import { Typography } from "antd"

const ProdukCard = ({ alamat, image, nama, desc }) => {
  return (
    <div className="produk-card">
      {/* <img src={variant} className="carousel-item-bg" alt="background produk" /> */}
      <div className="container-section-produk">
        <div className="carousel-item-inside">
          <Typography.Text className="desc-testimoni">{desc}</Typography.Text>
        </div>
        <div className="area-section-produk">
          <img className="img-section-produk" src={image} alt="logo produk" />
          <div className="area-text-produk">
            <Typography.Text className="name-testimoni">{nama}</Typography.Text>
            <Typography.Text className="prof-testimoni">
              {alamat}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProdukCard
