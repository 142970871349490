import pict1 from "resources/img/profile/Propict1.jpg"
import pict2 from "resources/img/profile/Propict2.jpg"
import pict3 from "resources/img/profile/Propict3.jpg"
import pict4 from "resources/img/profile/Propict4.jpg"

const testimoni = [
  {
    desc: "Produk lumayan komplit dan banyak diskon nya, harganya terjangkau karena promo beli bareng.",
    icon: pict1,
    nama: "Diat wibowo",
    alamat: "Banjarnegara",
  },
  {
    desc: "Barang sesuai pesanan dan kualitasnya oke2 banget! Dapet murah dengan beli bareng temen.",
    icon: pict2,
    nama: "Anggraeni",
    alamat: "Brebes",
  },
  {
    desc: "Suka belanja produk fashion disini karena bagus2 dan semuanya diskon kalo beli bareng2 temen.",
    icon: pict3,
    nama: "Syarif",
    alamat: "Batang",
  },
  {
    desc: "Sering beli skincare di Wisbeli, pake promo beli bareng jadi lebih murah dan pengiriman cepat.",
    icon: pict4,
    nama: "Rosenna",
    alamat: "Tangerang Selatan",
  },
]

export default testimoni
